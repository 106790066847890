<template>
  <router-link
    tag="a"
    class="d-flex align-items-start list-item card-spacer-x py-3"
    :to="{ name: 'manage_communication', params: { id: communication.id } }"
    data-inbox="message"
  >
    <div class="d-flex align-items-center pt-3">
      <div class="d-flex align-items-center flex-wrap w-xxl-250px mr-3" data-toggle="view">
        <UserThumbnail :user="communication.user" class="mr-2"></UserThumbnail>
        <div>
          <a href="javascript:void(0)" class="font-weight-bold text-dark-75 text-hover-primary font-size-sm">
            <AnonymisedUsername :user="communication.user"></AnonymisedUsername>
          </a>
          <span
            class="text-muted d-block font-size-sm"
          >
            <AnonymisedEmail :user="communication.user"></AnonymisedEmail>
          </span>
        </div>
      </div>
    </div>
    <div class="flex-grow-1 mt-2 mr-2" data-toggle="view">
      <div>
        <span class="font-weight-bold mr-2 font-size-sm">{{ communication.subject }} -</span>
        <span class="text-muted font-size-sm">{{ contentPreview }}</span>
      </div>
      <div class="mt-2">
        <span
          class="label font-weight-bolder label-inline mr-1"
          :class="'label-light-' + statusLabel.class"
        >
          {{ statusLabel.text }}
        </span>
      </div>
    </div>
    <div v-if="communication.status.int == 3" class="mt-2 mr-3 font-weight-bolder w-210px text-right font-size-sm pt-3 text-muted " data-toggle="view">
      <span> {{ dateText }} </span>
        <GenericDatetimeDisplay
          :long="true"
          :date="this.communication.delivery_date"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
    </div>
    <div v-else-if="communication.status.int == 4" class="mt-2 mr-3 font-weight-bolder w-210px text-right font-size-sm pt-3 text-muted " data-toggle="view">
      <span> {{ dateText }} </span>
        <GenericDatetimeDisplay
          :long="true"
          :date="this.communication.sent_date"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
    </div>
    <div v-else class="mt-2 mr-3 font-weight-bolder w-210px text-right font-size-sm pt-3 text-muted " data-toggle="view">
      <span> {{ dateText }} </span>
    </div>
  </router-link>
</template>

<script>
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "CommunicationPreview",

  props: {
    communication: { type: Object, required: true }
  },

  components: {
    UserThumbnail,
    GenericDatetimeDisplay
  },

  computed: {
    status: function() {
      let communication = this.communication;
      let now = new Date();
      let status = null;

      if (communication.draft == 1) {
        status = "draft";
      } else if (communication.draft == 0 && communication.delivery_date != null && communication.delivery_date < now) {
        status = "scheduled";
      } else if (communication.sent_date != null) {
        status = "sent";
      } else {
        status = "outbox";
      }

      return status;
    },
    contentPreview: function() {
      let content = null;

      if (this.communication.content.length >= 110) {
        content = this.communication.content.substring(0,99) + "...";
      } else {
        content = this.communication.content;
      }

      return content.replace(/<\/?[^>]+(>|$)/g, "");
    },
    statusLabel: function() {
      let status = {};

      if (this.communication.status.int == 2) {
        status.class = "danger";
        status.text = `${this.$t("SYSTEM.SENT_STATUS.DRAFT")}`;
      } else if (this.communication.status.int == 3) {
        status.class = "primary";
        status.text = `${this.$t("SYSTEM.SENT_STATUS.SCHEDULED")}`;
      } else if (this.communication.status.int == 4) {
        status.class = "success";
        status.text = `${this.$t("SYSTEM.SENT_STATUS.SENT")}`;
      } else if (this.communication.status.int == 5) {
        status.class = "warning";
        status.text = `${this.$t("SYSTEM.SENT_STATUS.OUTBOX")}`;
      }

      return status;
    },
    dateText: function() {
      let dateText = null;

      if (this.communication.status.int == 2) {
        dateText = `${this.$t("GENERAL.TEXT.DRAFT")}`
      } else if (this.communication.status.int == 3) {
        dateText = `${this.$t("GENERAL.TEXT.SCHEDULED")}` + ' ';
      } else if (this.communication.status.int == 4) {
        dateText = `${this.$t("GENERAL.TEXT.SENT")}` + ' ';
      } else if (this.communication.status.int == 5) {
        dateText = `${this.$t("GENERAL.TEXT.SENDING")}`
      }

      return dateText;
    }
  }
};
</script>
