<template>
<div>
  <div v-if="loadingComplete && !error.active" class="list list-hover" data-inbox="list">
    <template v-for="(communication, i) in communications">
      <CommunicationPreview
        :key="i"
        :communication="communication"
      >
      </CommunicationPreview>
    </template>
  </div>
  
  <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
    <b-spinner
      variant="primary"
      label="Spinning"
      class="table-loader my-3"
    >
    </b-spinner>
  </div>

  <b-alert v-if="error.active" show variant="light">
    {{ error.message }}
  </b-alert>

  <b-alert
    v-if="loadingComplete && !error.active && filterApplied && communications.length == 0"
    show variant="light"
    class="text-center"
  >
    {{ $t('TABLES.PROJECT_COMMUNICATE_PLACEHOLDERS.NO_FILTER_RESULTS') }}
  </b-alert>

  <span
    v-if="loadingComplete && !error.active && !filterApplied && communications.length == 0"
    class="text-dark-65 font-weight-bold d-block py-5 text-center"
  >
    {{ $t('TABLES.PROJECT_COMMUNICATE_PLACEHOLDERS.NO_COMMUNICATIONS') }}
    <router-link tag="a" :to="{ name: 'manage_communication' }">
      {{ $t('TABLES.PROJECT_COMMUNICATE_PLACEHOLDERS.NO_COMMUNICATIONS_CREATE') }}
    </router-link>
  </span>
</div>

</template>

<script>
import CommunicationPreview from "./Preview.vue";

export default {
  name: "CommunicateContent",

  props: {
    loadingComplete: { type: Boolean, required: true },
    error: { type: Object, required: true },
    communications: { type: Array, required: true },
    filterApplied: { type: Boolean, required: true }
  },

  components: {
    CommunicationPreview
  }
};
</script>
