<template>
  <div class="row d-inline mx-0">
    <div class="card card-custom gutter-b" style="min-height:85px">
      <div class="card-body p-3">
        <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="table-loader my-3"
          >
          </b-spinner>
        </div>
        <div v-if="loadingComplete">

          <div
            class="navi navi-hover navi-active navi-bold navi-icon-center navi-light-icon"
          >
            <!--start::StatusFilters -->
            <div>
              <div class="d-flex justify-content-start my-2">
                <p class="my-1 py-2 px-3 font-weight-bolder">{{ $t('GENERAL.PROJECT_COMMUNICATE_FILTER.STATUS') }}</p>
              </div>
              <template v-for="(status, i) in validFilterStatuses">
                <div
                  :key="i"
                  class="navi-item my-0"
                >
                  <a
                    href="javascript:void(0)"
                    class="navi-link px-3"
                    :class="{ 'active' : filters.status == status.value }"
                    @click="emitStatusChange(status.value)"
                  >
                    <span class="navi-text font-weight-normal font-size-lg">{{ status.title }}</span>
                    <span v-if="status.id != 1 && status.count > 0" class="navi-label">
                      <span :class="'label label-rounded font-weight-bolder label-light-' + status.class">{{ status.count }}</span>
                    </span>
                  </a>
                </div>
              </template>              
            </div>
            <!--end::StatusFilters -->

            <!--begin::Separator-->
            <div class="navi-item mt-10 mb-2"></div>
            <!--end::Separator-->

            <!--begin::Author Filters-->
            <div>
              <div class="d-flex justify-content-start my-2">
                <p class="my-1 py-2 px-3 font-weight-bolder">{{ $t('GENERAL.PROJECT_COMMUNICATE_FILTER.AUTHOR') }}</p>
              </div>

              <!--being::All Authors-->
              <div class="navi-item my-0">
                <a
                  href="javascript:void(0)"
                  class="navi-link px-3"
                  :class="{ 'active' : filters.author == 'all' }"
                  @click="emitAuthorChange('all')"
                >
                  <span class="navi-text font-weight-normal font-size-lg">{{ $t('GENERAL.PROJECT_COMMUNICATE_FILTER.ALL_AUTHORS') }}</span>
                </a>
              </div>
              <!--end::All Authors-->

              <!--begin::Community Managers-->
              <template v-for="(data, i) in validFilterAuthors">
                <div :key="i" class="navi-item my-0">
                  <a
                    href="javascript:void(0)"
                    class="navi-link px-3"
                    :class="{ 'active' : filters.author == data.author.id }"
                    @click="emitAuthorChange(data.author.id)"
                  >
                    <span class="navi-text font-weight-normal font-size-lg">
                      <AnonymisedUsername :user="data.author"></AnonymisedUsername>
                    </span>
                    <span class="navi-label">
                      <span class="label label-rounded font-weight-bolder label-light-primary">{{ data.communication_count }}</span>
                    </span>
                  </a>
                </div>
              </template>
              <!--end::Community Managers-->

            </div>
            <!--end::Author Filters-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommunicateFilter",

  props: {
    loadingComplete: { type: Boolean, required: true },
    error: { type: Object, required: true },
    validFilterAuthors: { type: Array, required: true },
    validFilterStatuses: { type: Array, required: true },
    filters: { type: Object, required: true }
  },

  methods: {
    emitStatusChange: function(value) {
      this.$emit("status_changed",value);
    },
    emitAuthorChange: function(user_id) {
      this.$emit("author_changed",user_id);
    }
  }
};
</script>
