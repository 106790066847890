<template>
  <div class="d-flex row justify-content-between" style="margin: 0px -7px !important">

    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 px-2">
      <CommunicateFilter
        :loadingComplete="loadingComplete"
        :error="error"
        :validFilterAuthors="validFilterAuthors"
        :validFilterStatuses="validFilterStatuses"
        :filters="filters"
        @status_changed="filters.status = $event"
        @author_changed="filters.author = $event"
      >
      </CommunicateFilter>
    </div>

    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10 px-2">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div v-if="loadingComplete" class="card-header">
          <div class="card-title col-sm-12 col-md-7 col-lg-5 col-xl-4 px-0">
            <h3 class="card-title align-items-start flex-column px-0 w-100">
              <div class="form-group mb-0 px-0 w-100">
                <input
                  id="project_communicate_search"
                  v-model="filters.search"
                  type="text"
                  :placeholder="$t('GENERAL.INPUT.SEARCH')"
                  class="form-control form-control-solid h-auto py-3 px-5"
                  :disabled="loadingComplete == false"
                />
              </div>
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
              tag="a"
              class="btn btn-sm btn-primary"
              :to="{ name: 'manage_communication' }"
            >
              {{ $t('TABLES.PROJECT_COMMUNICATE_NAVS.NEW') }}
            </router-link>
          </div>
        </div>
        <div class="card-body px-2 py-4">
          <CommunicateContent
            :loadingComplete="loadingComplete"
            :error="error"
            :communications="filteredCommunications"
            :filterApplied="filterApplied"
          >
          </CommunicateContent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { POPULATE_SYS_ADMINISTRATOR_LIST } from "@/core/services/store/core/user.module";
import { POPULATE_PROJECT_USERS_LIST } from "@/core/services/store/project/project_users.module";
import { POPULATE_PROJECT_COMMUNICATIONS_LIST } from "@/core/services/store/project/project_communications.module";

import CommunicateFilter from "./CommunicateFilter.vue";
import CommunicateContent from "./CommunicateContent.vue";

export default {
  name: "ProjectCommunicateSummary",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 3
      },
      error: {
        active: false,
        message: ""
      },
      statusFilters: [
        { id: 1, value: "all", title: `${this.$t("SYSTEM.SENT_STATUS.ALL")}`, icon: 'Communication/Mail.svg', class: 'primary' },
        { id: 2, value: 2, title: `${this.$t("SYSTEM.SENT_STATUS.DRAFT")}`, icon: 'Communication/Mail-error.svg', class: 'danger' }, 
        { id: 3, value: 3, title: `${this.$t("SYSTEM.SENT_STATUS.SCHEDULED")}`, icon: 'Communication/Snoozed-mail.svg', class: 'primary' },
        { id: 4, value: 4, title: `${this.$t("SYSTEM.SENT_STATUS.SENT")}`, icon: 'Communication/Readed-mail.svg', class: 'success' },
        { id: 5, value: 5, title: `${this.$t("SYSTEM.SENT_STATUS.OUTBOX")}`, icon: 'Communication/Outgoing-mail.svg', class: 'warning'}
      ],
      filters: {
        search: "",
        status: "all",
        author: "all"
      }
    }
  },

  components: {
    CommunicateFilter,
    CommunicateContent
  },

  mounted() {
    this.fetchProjectCommunications();

    if (this.currentUser.relation_to_project == "super_admin") {
      this.fetchSystemAdmins();
    } else {
      this.pageLoader.componentsCompleted++;
    }
    
    this.fetchProjectCommunityManagers();
  },

  methods: {
    fetchProjectCommunications: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          user: 'with'
        }
      }

      this.$store.dispatch(POPULATE_PROJECT_COMMUNICATIONS_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = "We're having some issues retrieving the project communications, please check back later or contact the helpdesk";
        })  
    },
    fetchSystemAdmins: function() {
      let payload = {
        params: {
          paginate: false,
          read_only: 1          
        }
      }

      this.$store.dispatch(POPULATE_SYS_ADMINISTRATOR_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = "We're having some issues retrieving the system admins, please check back later or contact the helpdesk";
        })
    },
    fetchProjectCommunityManagers: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          x_filter_status: [1],
          x_filter_roles: [3]          
        }
      }

      this.$store.dispatch(POPULATE_PROJECT_USERS_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = "We're having some issues retrieving the community managers, please check back later or contact the helpdesk";
        })
    }
  },

  computed: {
    ...mapGetters([
      "userList",
      "projectUsers",
      "projectInfo",
      "projectCommunicationList",
      "projectCommunicationErrors",
      "currentUser"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    filterApplied: function() {
      if (this.filters.status == "all" && this.filters.author == "all" && this.filters.search == "") {
        return false;
      } else {
        return true;
      }
    },
    validFilterAuthors: function() {
      let allAuthors = this.userList.concat(this.projectUsers);
      let validAuthors = [];

      const self = this;

      allAuthors.forEach(function (author) {
        let communications = self.projectCommunicationList.filter(function( obj ) {
          return obj.user_id == author.id;
        });

        if (communications.length > 0) {
          let filterObject = { author: author, communication_count: communications.length };
          validAuthors.push(filterObject);
        }
      });

      return validAuthors;
    },
    validFilterStatuses: function() {
      let allStatuses = this.statusFilters;
      let validStatuses = [];

      const self = this;

      allStatuses.forEach(function (status) {
        status.count = self.projectCommunicationList.filter(function( obj ) {
          return obj.status.int == status.value;
        }).length;
        validStatuses.push(status);
      });

      return validStatuses;
    },
    filteredCommunications: function() {
      let projectCommunications = [];

      if (this.filters.status == "all" && this.filters.author == "all" && this.filters.search == "") {
        projectCommunications = this.projectCommunicationList;
      } else {
        projectCommunications = this.projectCommunicationList;
        const self = this;

        if (this.filters.author != "all") {
          projectCommunications = projectCommunications.filter(function ( obj ) {
            return obj.user_id == self.filters.author;
          });
        }

        if (this.filters.search != "") {
          let search = this.filters.search.toLowerCase();
          projectCommunications = projectCommunications.filter(function ( obj ) {
            return obj.subject.toLowerCase().includes(search) ||
              obj.content.toLowerCase().includes(search) ||
              obj.user.display_name.toLowerCase().includes(search) ||
              obj.user.email.toLowerCase().includes(search);
          });
        }

        if (this.filters.status != "all") {
          const self = this;
          projectCommunications = projectCommunications.filter(function( obj ) {
            return obj.status.int == self.filters.status;
          })
        }
      }

      return projectCommunications;
    }
  }
};
</script>
